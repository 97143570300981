import React from "react";
import { useContext, useEffect, useState } from "react";
import Contexto from "../context/Contexto";
import "../assets/css/DesignSeason.css";
import DS10 from "../assets/statics/DS/ds10.jpg";
import DS08 from "../assets/statics/DS/ds8.jpg";
import letra1 from "../assets/statics/DS/letra1.png";
import letra2 from "../assets/statics/DS/letra2.png";
import letra3 from "../assets/statics/DS/letra3.png";
import letra4 from "../assets/statics/DS/letra4.png";
import letra5 from "../assets/statics/DS/letra5.png";
import letra6 from "../assets/statics/DS/letra6.png";
import letra7 from "../assets/statics/DS/letra7.png";
import TAC from "../assets/statics/DS/tac.png";
import CR from "../assets/statics/DS/cr.png";
import MercadoPago from "../components/MercadoPago";

function DesignSeason() {
  const {
    loguearUsuario,
    mensajes,
    mandarMensajes,
    Griseado,
    usuario,
    cliente,
    seteos,
    agregarCarrito,
    productos,
    cambiaPrecio,
  } = useContext(Contexto);
  const [descontame, setDescontame] = useState(false);

  const [precioFinal, setPrecioFinal] = useState(36000);
  const [clienteu, setClienteu] = useState(cliente);
  const [cursosQueTienen, setCursosQueTienen] = useState(cliente);
  const [precioLeyenda, setPrecioLeyeneda] = useState("de $20.400 a $36.000");

  let tieneEsteCurso = (curso) => {
    if (seteos) {
      let figma = seteos.BD_TAC.figma;
      let fundamentos = seteos.BD_TAC.fundamentos;
      for (var i in fundamentos) {
        if (fundamentos[i] == usuario.email) return true;
      }
    }
  };

  let tieneFundamentos = () => {
    if (seteos) {
      let fundamentos = seteos.BD_TAC.fundamentos;
      for (var i in fundamentos) {
        if (fundamentos[i] == usuario.email) return true;
      }
    }
  };

  let tieneFigma = () => {
    if (seteos) {
      let figma = seteos.BD_TAC.figma;
      for (var i in figma) {
        if (figma[i] == usuario.email) return true;
      }
    }
  };
  let handleComprar = () => {
    if (cliente == null) {
      loguearUsuario();
    } else {
      checkTAC();
    }
  };
  useEffect(() => {
    console.log("setClienteu");
    setClienteu(cliente);
  }, [cliente]);

  const checkTAC = () => {
    console.log("checkTAC");
    if (seteos) {
      let clien = clienteu;
      tieneEsteCurso("fundamentos");
      if (tieneFigma()) {
        clien.figma = true;
      } else {
        clien.figma = false;
      }
      if (tieneFundamentos()) {
        clien.fundamentos = true;
      } else {
        clien.fundamentos = false;
      }

      setClienteu(clien);
      ajustoPrecio();
    }
  };
  const ajustoPrecio = () => {
    let precioAjuste = 0;

    let txt = `Por tener: `;
    if (clienteu.ux) {
      precioAjuste = precioAjuste + 4000;
      txt = txt + " UX,";
    }
    if (clienteu.ui) {
      precioAjuste = precioAjuste + 4000;
      txt = txt + " UI,";
    }
    if (clienteu.figma) {
      precioAjuste = precioAjuste + 4000;
      txt = txt + " Figma,";
    }
    if (clienteu.fundamentos) {
      precioAjuste = precioAjuste + 4000;
      txt = txt + " Fundamentos,";
    }

    ////// 15% alumnes viejos

    if (
      cliente.hasOwnProperty("summerhack23") ||
      cliente.hasOwnProperty("summerhack2022") ||
      cliente.hasOwnProperty("summerhack2021") ||
      cliente.hasOwnProperty("summerhack2021feb") ||
      cliente.hasOwnProperty("winterhack") ||
      cliente.hasOwnProperty("designseason") ||
      cliente.hasOwnProperty("designseason2") ||
      cliente.hasOwnProperty("designseason3")
    ) {
      var prev = 36000 - precioAjuste;
      var quince = (prev / 100) * 15;

      precioAjuste = precioAjuste + quince;

      txt = txt + "  - 15% extra por alumnx recurrente,";
    }

    txt = txt + " te descontamos en total$" + precioAjuste;
    setPrecioFinal(36000 - precioAjuste);
    setPrecioLeyeneda(`$${36000 - precioAjuste}`);

    setCursosQueTienen(txt);
    setDescontame(true);
    //cambiaPrecio("/designseason3", 36000 - precioAjuste);
  };
  const descarguita = () => {
    window
      .open(
        "https://codearock.com/descargas/DesignSeason_Programa.pdf?id=pepeloco",
        "_blank"
      )
      .focus();
  };
  let carrito = [
    [
      {
        url: "/designseason4",
        img_prod: "prod-designseason.png",
        nombre: "Design Season 3",
      },
    ],
  ];

  return (
    <>
      <section className="ds">
        <div className="ds_pic off" id="DS01"></div>
        <div className="ds_txt naranjaDSB alineadoLeft">
          <h1>Aprendé a diseñar experiencias e interfaces profesionales</h1>
          <br />
          <div className="infoComprA">
            {precioFinal == 36000 ? (
              <p className="ds-precio">de $20.400 a $36.000</p>
            ) : (
              <>
                <p className="ds-precio-viejos">{cursosQueTienen}</p>
                <p className="ds-precio">{precioLeyenda}</p>
                <del className="ds-del">de $20.400 a $36.000</del>
              </>
            )}
            {!descontame ? (
              <button
                className="btn-azul"
                onClick={() => {
                  handleComprar();
                }}
              >
                "Dame mis descuentos"
              </button>
            ) : (
              <MercadoPago
                precio={precioFinal}
                nombre={cliente.nombre}
                username={cliente.username}
                img_prod={"imgProd"}
                uid={cliente.uid}
                email={cliente.email}
                carrito={carrito}
                regalito={false}
                ceci={true}
              ></MercadoPago>
            )}

            <p className="ds-cuotas">3 y 6 cuotas sin interés</p>
          </div>
          <img src={letra1} alt="" className="letra-decorate" />
        </div>
      </section>
      <section className="ds">
        <div className="ds_txt celesteDSB" id="ds1">
          <p className="txt-cuerpo">
            Sumate a Jony y Ceci en este programa intensivo de 9 semanas y
            convertite en uno de los perfiles más buscados del mundo IT. Vas a
            aprender todo lo necesario para ser parte del mundo del diseño de
            interfaces: UX, UI, Fundamentos de diseño y Figma.
          </p>

          <br />
          {/* <div className="infoComprA">
            {!descontame ? (
              <button
                className="btn-naranja"
                onClick={() => {
                  handleComprar();
                }}
              >
                "Dame mis descuentos"
              </button>
            ) : (
              <MercadoPago
                precio={precioFinal}
                nombre={cliente.nombre}
                username={cliente.username}
                img_prod={"imgProd"}
                uid={cliente.uid}
                email={cliente.email}
                carrito={""}
                regalito={false}
                ceci={true}
              ></MercadoPago>
            )}

            {precioFinal == 36000 ? (
              <p className="ds-precio">de $20.400 a $36.000</p>
            ) : (
              <>
                <p className="ds-precio-viejos">{cursosQueTienen}</p>
                <p className="ds-precio">{precioLeyenda}</p>
                <del className="ds-del">de $20.400 a $36.000</del>
              </>
            )}
            <p className="ds-cuotas">3 y 6 cuotas sin interés</p>
            <img src={letra2} alt="" className="letra-decorateLeft" />
          </div> */}
        </div>

        <div className="ds_pic" id="ds2"></div>
      </section>
      <section className="ds">
        <div className="ds_txt naranjaDSB" id="que1">
          <h1 className="titulos-ds">¿QUÉ ES?</h1>
          <br />
          <p className="txt-cuerpo">
            Design Season incluye 4 cursos distribuidos en entregas diarias para
            que vayamos todes al mismo ritmo. Además{" "}
            <strong>Masterclass</strong> sobre temas complementarios,{" "}
            <strong>Blips</strong> con tips concretos y <strong>stories</strong>{" "}
            con más datita exclusiva. Y lo más importante,{" "}
            <strong>retos semanales</strong> para practicar y terminar el
            programa con un proyecto propio mostrable. En Discord hay un{" "}
            <strong>canal de soporte</strong> donde interactuamos a diario.
          </p>
          <br />
          <button
            className="btn-descarga"
            onClick={() => {
              descarguita();
            }}
          >
            Ver programa completo
          </button>
          <img src={letra3} alt="" className="letra-decorateLeft" />
        </div>
        <div className="ds_pic" id="que2"></div>
      </section>
      <section className="ds">
        <div className="ds_pic" id="c2">
          <img src={DS08} alt="" />
        </div>
        <div className="ds_txt celesteDSB" id="c1">
          <h1 className="titulos-ds">¿CUÁNDO?</h1>
          <br />
          <p className="txt-cuerpo">
            El programa intensivo dura 9 semanas, del 2 de mayo al 30 de junio
            de 2023. Al finalizar vas a seguir teniendo todos los videos en tu
            cuenta hasta el 15 de julio de 2023 por si querés repasar. La
            entrega de emails y contenido durante las 9 semanas es de lunes a
            viernes y calculamos que puede demandarte entre 2 y 3 horas de
            atención y trabajo por día.
          </p>
          <br />
          <button
            className="btn-descarga"
            onClick={() => {
              descarguita();
            }}
          >
            Ver programa completo
          </button>
          <img src={letra6} alt="" className="letra-decorate" />
        </div>
      </section>
      <section className="ds">
        <div className="ds_txt naranjaDSB" id="quien1">
          <h1 className="titulos-ds">¿CON QUIÉN?</h1>
          <br />
          <p className="txt-cuerpo">
            Este programa fue creado y dirigido por{" "}
            <strong>Jonatan Ariste</strong> y{" "}
            <strong>Ceci Escamilla Gigirey</strong>. Quizás los conozcas más
            como <strong>CodeaRock</strong> (Jony) y{" "}
            <strong>Talleres a Color</strong> (Ceci), el nombre de sus escuelas
            digitales en donde te enseñan hace años a través de sus cursos
            online. Son un mix de perfiles que te va a asegurar una doble mirada
            y un abordaje amplio en el mundo del diseño de interfaces.
          </p>
          <br />
          <button
            className="btn-descarga"
            onClick={() => {
              descarguita();
            }}
          >
            Ver programa completo
          </button>
          <img src={letra5} alt="" className="letra-decorateLeft" />
        </div>
        <div className="ds_pic" id="quien2">
          <img src={CR} alt="" className="loguines" />
          <img src={TAC} alt="" className="loguines" />
        </div>
      </section>
      <section className="ds">
        <div className="ds_pic" id="c3">
          <img src={DS10} alt="" />
        </div>
        <div className="ds_txt celesteDSB" id="c4">
          <h1 className="titulos-ds">¿POR QUÉ?</h1>
          <br />
          <p className="txt-cuerpo">
            El perfil UX/UI es cada vez más requerido por lo tanto es una forma
            perfecta de insertarse en el creciente mercado de la tecnología. En
            Design Season vamos a prepararte para que empieces a trabajar en
            UX/UI teniendo las bases y fundamentos para tomar decisiones de
            diseño. ¡Y la práctica no puede faltar! Realmente hace la
            diferencia.
          </p>
          <br />
          <button
            className="btn-descarga"
            onClick={() => {
              descarguita();
            }}
          >
            Ver programa completo
          </button>
          <img src={letra4} alt="" className="letra-decorate" />
        </div>
      </section>
      <section className="cierre">
        <div className="cierreds">
          <h1 className="titulos-ds">
            ¿Cómo funcionan los precios del Design Season?
          </h1>
          <br />
          <p className="txt-cuerpo">
            El valor del ticket completo es $36.000, pero te vamos a descontar
            $4.000 por cada uno de estos cursos que tengas desde antes: UX / UI
            de Codea Rock, Fundamentos del DG y/o Figma de Talleres a Color.{" "}
            <br />
            Si ya hiciste alguno de los programas tenes un 15% extra.
            <br />
          </p>
          <div className="infoComprA">
            {precioFinal == 36000 ? (
              <p className="ds-precio">de $20.400 a $36.000</p>
            ) : (
              <>
                <p className="ds-precio-viejos">{cursosQueTienen}</p>
                <p className="ds-precio">{precioLeyenda}</p>
                <del className="ds-del">de $20.400 a $36.000</del>
              </>
            )}
            {!descontame ? (
              <button
                className="btn-azul"
                onClick={() => {
                  handleComprar();
                }}
              >
                "Dame mis descuentos"
              </button>
            ) : (
              <MercadoPago
                precio={precioFinal}
                nombre={cliente.nombre}
                username={cliente.username}
                img_prod={"imgProd"}
                uid={cliente.uid}
                email={cliente.email}
                carrito={""}
                regalito={false}
                ceci={true}
              ></MercadoPago>
            )}
            <p className="ds-cuotas">3 y 6 cuotas sin interés</p>
            <br /> <br />
          </div>
        </div>
        <img src={letra7} alt="" className="cierreLetra" />
      </section>

      <section className="cierre cel">
        <h1 className="titulos-ds">TESTIMONIOS</h1>
        <p className="txt-cuerpo bajadita">
          Trabajos de Alumnxs de la anterior edición
        </p>
        <br />
        <div className="cierreds">
          <img
            src="https://escueladevrock.com/statics/ds4/sasha1-s.jpg"
            alt=""
          />
          <img
            src="https://escueladevrock.com/statics/ds4/sasha2-s.jpg"
            alt=""
          />
          <p className="txt-cuerpo">
            Me encantó todo, mucho más de lo que pensaba. Tremenda la
            información que me dieron en solo 3 meses, súper completo todo y
            darme cuenta que pude diseñar una app entera en tan poco tiempo me
            parecía impensado, gracias gracias ❤️
            <br /> <br />
            <strong>Sasha Garazi</strong>
            <br /> <br />
          </p>
        </div>
        <div className="cierreds">
          <img
            src="https://escueladevrock.com/statics/ds4/bruno1-s.jpg"
            alt=""
          />
          <img
            src="https://escueladevrock.com/statics/ds4/bruno2-s.jpg"
            alt=""
          />
          <p className="txt-cuerpo">
            Empecé el design season sin saber mucho de diseño, para ver si podía
            llegar a ser ese "algo" que me gustara, y salgo sabiendo que si, me
            encantó, aprendí muchísimo y tengo muchísimas ganas de diseñar,
            gracias al curso sé que voy a dedicarme a estudiar diseño de todos
            los tipos. Fue una muy linda experiencia y es una lástima que se
            haya terminado, tanto Jony como Ceci están siempre disponibles para
            responder dudas y eso aporta muchísimo, un muy lindo curso.
            <br /> <br />
            <strong>Bruno Sarraga</strong>
            <br /> <br />
          </p>
        </div>
        <div className="cierreds">
          <img src="https://escueladevrock.com/statics/ds4/mai1-s.jpg" alt="" />
          <img src="https://escueladevrock.com/statics/ds4/mai2-s.jpg" alt="" />
          <p className="txt-cuerpo">
            <strong>Mai Fazzini </strong>
            <br /> <br />
          </p>
        </div>
        <div className="cierreds">
          <img
            src="https://escueladevrock.com/statics/ds4/kiwi1-s.jpg"
            alt=""
          />
          <img
            src="https://escueladevrock.com/statics/ds4/kiwi2-s.jpg"
            alt=""
          />
          <p className="txt-cuerpo">
            ¡Excelente! Como siempre Ceci y Jony explicando todo muy claro y con
            ejemplos reales ❤️ . Además de enseñarnos muchísimo sobre
            herramientas y conceptos teóricos, durante todo el curso están
            disponibles para despejar dudas, y siempre fomentando la idea de
            armar comunidad entre todas las personas que participan. Imposible
            terminar la cursada y no tener ganas de ponerte a armar proyectos
            con todo lo aprendido.
            <br />
            <br /> <strong>Facundo Kiwi </strong>
            <br /> <br />
          </p>
        </div>

        <div className="cierreds">
          <img
            src="https://escueladevrock.com/statics/ds4/florchuna-s.jpg"
            alt=""
          />
          <img
            src="https://escueladevrock.com/statics/ds4/florchuna2-s.jpg"
            alt=""
          />
          <p className="txt-cuerpo">
            Hice el DS3 con Jony y Ceci, fue revelador en muchos aspectos,
            práctico y super divertido. Se armó un grupo humano muy valioso, nos
            hemos dado mucho aporte de conocimiento y empuje.
            <br />
            <br /> <strong>Flor Sosa</strong>
            <br /> <br />
          </p>
        </div>
        <div className="cierreds">
          <img src="https://escueladevrock.com/statics/ds4/noe1-s.jpg" alt="" />
          <img src="https://escueladevrock.com/statics/ds4/noe2-s.jpg" alt="" />
          <p className="txt-cuerpo">
            Soy reincidente en el DS por decisión personal, y estoy feliz de
            haberlo hecho. La verdad es que no sólo aprendés de todo el
            contenido que comparten Ceci y Jony, que es un montón, porque no se
            guardan nada, sino que también la sinergia con los compañer@s es
            genial. Se aprende mucho, se comparte, y nos divertimos en el
            proceso. A mí parecer este sistema híbrido, con los retos semanales
            es genial porque te ayuda a ir fijando lo aprendido. Lo súper
            recomiendo.
            <br />
            <br /> <strong>Noe Funicello</strong>
            <br /> <br />
          </p>
        </div>

        <div className="cierreds">
          <img
            src="https://escueladevrock.com/statics/ds4/hernan1-s.jpg"
            alt=""
          />
          <img
            src="https://escueladevrock.com/statics/ds4/hernan2-s.jpg"
            alt=""
          />
          <p className="txt-cuerpo">
            Hice el Design Season 3, un curso intensivo compuesto de 4 cursos
            intensivos de UI/UX/Figma/Fundamentos de Diseño, y la verdad no pude
            quedar más contento. En los videos tanto de Jony como de Ceci se les
            nota la pasión por enseñar y son super claros en todos los
            conceptos. Los videos son dinámicos y tienen la duración ideal para
            entender un concepto sin dispersarse. En cada uno de los cursos se
            aprende un montón y las correcciones en vivo sumado al canal de
            discord son un gran soporte. En apenas 9 semanas y arrancando de
            cero, (en mi caso sin ningún conocimiento en ninguna de las áreas)
            pudimos diseñar una app que quedó lista para desarrollar y para un
            portfolio. Lo recontra recomiendo y espero con ansiedad nuevos
            cursos para poder anotarme!
            <br />
            <br /> <strong>Hernan Marques</strong>
          </p>
        </div>
      </section>
    </>
  );
}

export default DesignSeason;
