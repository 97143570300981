import React from "react";
import "../assets/css/MiCurso.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import InfoPrograma from "./InfoPrograma";
import { useEffect, useState } from "react";
import EnviarReto from "./EnviarReto";
const MiCurso = (props) => {
  const { Griseado, bajarCertificado } = useContext(Contexto);
  const { curso, restantes, info, nombre, totales, examen } = props;
  let { esPrograma } = info;

  const [examenAprobado, setExamenAprobado] = useState(false);
  const [totalProgreso, setTotalProgreso] = useState(0);
  useEffect(() => {
    if (examen && examen.hasOwnProperty(curso)) {
      setExamenAprobado(examen[curso].aprobado);
    }
    if (totales && totales.hasOwnProperty(curso)) {
      if (totales[curso]) {
        setTotalProgreso(totales[curso].total);
      }
    }
    console.log("restantes::");
  }, []);

  return (
    <>
      <div
        className={"item-curso"}
        style={{ background: info.colorin ? info.colorin : "" }}
      >
        {curso == "designseason4" && <EnviarReto key="retin" />}
        {info.hasOwnProperty("logo") && (
          <img
            src={`https://escueladevrock.com/${info.logo}`}
            alt={nombre}
            className="micurso-loguito"
          />
        )}

        <p>
          <strong>{info.nombre}</strong>
        </p>
        {restantes < 0 ? (
          <p>No te quedan días :(</p>
        ) : (
          <p>Te quedan {restantes} días</p>
        )}
        {totalProgreso > 99 &&
          (examenAprobado ? (
            <button
              onClick={() => {
                bajarCertificado(curso);
              }}
              className="btn-loco"
            >
              Bajar certificado
            </button>
          ) : (
            <button
              className="cursoComprado--btn  btn-loco"
              onClick={() => {
                Griseado(`examenes/${curso}`);
              }}
            >
              Rendir examen <i className="fas fa-file-download"></i>
            </button>
          ))}

        {restantes > 0 ? (
          <button
            className="btn-loco"
            onClick={() => {
              Griseado(`ver/${curso}`);
            }}
          >
            Ver curso
          </button>
        ) : (
          <>
            <br />
            <Link to="/renovaciones" className="blanco btn-loco">
              Renovar este curso
            </Link>
          </>
        )}
        {totalProgreso ? (
          <>
            <progress value={totalProgreso} max="100" id="progreso"></progress>
            <br></br>Ya viste el {totalProgreso.toFixed(2)}%
          </>
        ) : (
          <p className="cursoComprado_inicialo">
            ¡Arrancá ya a ver este curso!
          </p>
        )}
        {esPrograma && <InfoPrograma nombre={curso}></InfoPrograma>}
      </div>
    </>
  );
};

export default MiCurso;
