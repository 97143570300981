import React from "react";
import LoaderImg from "../assets/statics/logoCodeaRock.gif";

import "../assets/css/Loader.css";
const Loader = (props) => {
  return (
    <>
      <div className="loader-wraper">
        <img src={LoaderImg} alt="Logo EscyelaDevRock" className="loader-img" />
      </div>
    </>
  );
};

export default Loader;
